import React from "react";
import ReactPaginate from 'react-paginate';

const Paginate = ({ totalPages, setCurrentPage }) => {
  const handlePageClick = (selectedItem) => { setCurrentPage(selectedItem.selected) };

  return <ReactPaginate
    previousLabel='Previous Page'
    nextLabel='Next Page'
    breakLabel={'...'}
    pageCount={totalPages}
    marginPagesDisplayed={2}
    pageRangeDisplayed={5}
    onPageChange={handlePageClick}
    containerClassName={'pagination'}
    activeClassName={'active'}
  />;
};

export default Paginate;
