import React, { useRef, useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import Draw from "./ClaimDetailsDraw";
import ClaimDetailsTable from "./ClaimsDetailsTable";
import { wholeNumberWithCommas } from "../../utils/NumberFormatter";
import Notice from "../../Notice";
import Http from "../http";
import useDeepCompareEffect from "../../Hooks/useDeepCompareEffect";
import Paginate from "../../utils/Paginate";

const ReportsIndex = (props) => {
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const entityID = urlParams.get("id");

  const [searchQuery, setSearchQuery] = useState({
    id_340b: entityID ? entityID : props.entities[0].value,
    manufacturer: props.manufacturers[0].value,
    date_range: { start: moment().startOf("month").format("YYYY-MM-DD"), end: moment().format("YYYY-MM-DD") },
  });
  const [claims, setClaims] = useState([]);
  const [isClaimsLoading, setIsClaimsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [conformingClaimsCount, setConformingClaimsCount] = useState(0);
  const [nonConformingClaimsCount, setNonConformingClaimsCount] = useState(0);

  useDeepCompareEffect(() => {
    fetch();
  }, [searchQuery, currentPageIndex]);

  const fetch = async () => {
    if (!searchQuery.date_range.start || !searchQuery.date_range.end) return;
    setIsClaimsLoading(true);
    const pagedQuery = {...searchQuery, page: currentPageIndex + 1}

    await Http.fetchQuery(props.query_path, pagedQuery)
      .then((res) => {
        if (_.isEmpty(res.claims)) {
          setNotice({ kind: "success", open: true, message: "The current selection and filters returned no results" });
        }
        setClaims(res.claims);
        setTotalPages(res.total_pages);
        setConformingClaimsCount(res.conforming_claims_count);
        setNonConformingClaimsCount(res.non_conforming_claims_count);
      })
      .catch((error) => {
        setNotice({ kind: "error", open: true, message: "Error fetching data" });
      }).finally(() => setIsClaimsLoading(false));
  };

  const handleSearch = (name, value) => {
    setSearchQuery({ ...searchQuery, [name]: value });
  };

  const handleSort = (column, dir) => {
    setSearchQuery({ ...searchQuery, sort: column, sort_dir: dir });
  }

  const averageConformingClaimsCount = () => {
    if (conformingClaimsCount === 0) return 0;

    return _.round((conformingClaimsCount / (conformingClaimsCount + nonConformingClaimsCount)) * 100, 1);
  }

  return (
    <div style={{ display: "flex" }}>
      <div className="content__container">
        <div className="page-details__container">
          <div className="page-details__container__info">
            <div className="page-details__title">Claims Details</div>
            <div className="breadcrumbs__container">
              <div className="breadcrumbs__crumb breadcrumbs__crumb__active">Reports</div>
            </div>
          </div>
          <div className="reports__grid" style={{ marginTop: 20 }}>
            <div className="div1">
              <div className="tile__well" style={{ height: "100%" }}>
                <div className="tile">
                  <div className="tile__header tile__header--spread">
                    <div className="t--md">Conforming Claims</div>
                  </div>
                  <div className="flex__container flex__container--align-center">
                    <div className="t-500 t--xl">
                      {wholeNumberWithCommas(conformingClaimsCount)}
                    </div>
                    <div className="t--sm ml-2">Average</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div2">
              <div className="tile__well" style={{ height: "100%" }}>
                <div className="tile">
                  <div className="tile__header tile__header--spread">
                    <div className="t--md">% Conforming Claims</div>
                  </div>
                  <div className="flex__container flex__container--align-center">
                    <div className="t-500 t--xl">
                      {`${averageConformingClaimsCount()}%`}
                    </div>
                    <div className="t--sm ml-2">Average</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div3">
              <div className="tile__well" style={{ height: "100%" }}>
                <div className="tile">
                  <div className="tile__header tile__header--spread">
                    <div className="t--md">Non Conforming Claims</div>
                  </div>
                  <div className="flex__container flex__container--align-center">
                    <div className="t-500 t--xl">
                      {wholeNumberWithCommas(nonConformingClaimsCount)}
                    </div>
                    <div className="t--sm ml-2">This Month</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        { totalPages > 1 && <Paginate totalPages={totalPages} setCurrentPage={setCurrentPageIndex} /> }
        <ClaimDetailsTable claims={claims} isLoading={isClaimsLoading} handleSort={handleSort} />
      </div>
      <div className="draw__container">
        <Draw
          entities={props.entities}
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          handleSearch={handleSearch}
          manufacturers={props.manufacturers}
          products={props.products}
        />
      </div>
      <Notice details={notice} />
    </div>
  );
};

export default ReportsIndex;
