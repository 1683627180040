import React, { useState, useEffect } from "react";
import { Refresh } from "@material-ui/icons";
import "react-datepicker/dist/react-datepicker.css";
import ChipSelector from "../../Draw/ChipSelector";
import DateSelector from "./DateSelector";

const ReportsDraw = (props) => {
  const [selectedEntity, setSelectedEntity] = useState(props.searchQuery.id_340b);
  const [selectedManufacturer, setSelectedManufacturer] = useState(props.searchQuery.manufacturer);
  const [selectedProductName, setSelectedProductName] = useState(null);
  const [status, setStatus] = useState(null);
  const [dateRange, setDateRange] = useState(props.searchQuery.date_range);

  useEffect(() => {
    props.handleSearch("date_range", dateRange);
  }, [dateRange]);

  useEffect(() => {
    props.handleSearch("id_340b", selectedEntity);
  }, [selectedEntity]);

  useEffect(() => {
    props.handleSearch("status", status);
  }, [status]);

  useEffect(() => {
    setSelectedProductName(null);
    props.handleSearch("manufacturer", selectedManufacturer);
  }, [selectedManufacturer]);

  useEffect(() => {
    props.handleSearch("product_name", selectedProductName);
  }, [selectedProductName]);

  return (
    <div>
      <div className="draw__tools__container">
        <div className="btn btn--sqr">
          <Refresh fontSize="small" />
        </div>
        {/* <CSVLink data={props.claims} filename={`340B_ESP_Report_Export_${new Date().toISOString().split("T")[0]}.csv`}>
          <div className="btn btn--light">Download CSV</div>
        </CSVLink> */}
      </div>
      <DateSelector setDateRange={setDateRange} />
      <div className="draw__divider" />
      <div className="draw__item">
        <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">Covered Entity</div>
        <div className="chip__well">
          <ChipSelector
            items={props.entities}
            selected={selectedEntity}
            setSelected={setSelectedEntity}
            required={true}
          />
        </div>
      </div>
      <div className="draw__divider" />
      <div className="draw__item">
        <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">Status</div>
        <div className="chip__well">
          <ChipSelector
            items={[
              { value: "CONFORMING", label: "Conforming" },
              { value: "NON-CONFORMING", label: "Non-Conforming" },
              { value: "OTHER", label: "Other" }
            ]}
            selected={status}
            setSelected={setStatus}
          />
        </div>
      </div>
      <div className="draw__divider" />
      <div className="draw__item">
        <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">Manufacturers</div>
        <div className="chip__well">
          <ChipSelector
            items={props.manufacturers}
            selected={selectedManufacturer}
            setSelected={setSelectedManufacturer}
            required={true}
          />
        </div>
      </div>
      <div className="draw__divider" />
      <div className="draw__item">
        <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">Products</div>
        <div className="chip__well">
          <ChipSelector
            items={props.products ? props.products[selectedManufacturer] : []}
            selected={selectedProductName}
            setSelected={setSelectedProductName}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportsDraw;
