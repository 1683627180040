import { useEffect, useRef } from 'react';
import _ from 'lodash';

const useDeepCompareEffect = (callback, dependencies) => {
  const firstRenderRef = useRef(true);
  const dependenciesRef = useRef(dependencies);

  if (!_.isEqual(dependencies, dependenciesRef.current)) {
    dependenciesRef.current = dependencies;
    firstRenderRef.current = false;
  }

  useEffect(() => {
    if (!firstRenderRef.current) {
      return callback();
    }
  }, [dependenciesRef.current]);
};

export default useDeepCompareEffect;
