import React, { Fragment, useState } from "react";
import { ExpandMore, GetApp } from "@material-ui/icons";
import SubmissionSummaryChart from "./SubmissionSummaryChart";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
} from "@tanstack/react-table";
import Notice from "../Notice";

const columnHelper = createColumnHelper();

const LoadingIndicator = () => {
  return (
    <td className="loading">
      <div className="loading-indicator loading-indicator--blue loading-indicator--large" />
    </td>
  )
}

export default function ClaimsTable({ submissions, api_submissions_row_data_path }) {
  const [data] = useState(() => submissions);
  const [ rowsLoading, setRowsLoading ] = useState([]);
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "",
  });

  const startRowLoading = (row) => setRowsLoading([...rowsLoading, row.original.guid ]);
  const stopRowLoading = (row) => setRowsLoading(rowsLoading.filter(guid => guid !== row.original.guid));
  const isRowLoading = (row) => rowsLoading.includes(row.original.guid);

  const fetchRow = (row) => {
    startRowLoading (row);
    fetch(`${api_submissions_row_data_path}/${row.original.guid}`)
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.error);
          });
        }
        return response.json();
      })
      .then((data) => {
        row.original = {...row.original, ...data};
      })
      .catch((error) => {
        row.toggleExpanded();
        setNotice({ kind: "error", open: true, message: "Error fetching data." });
      }).finally(() => stopRowLoading(row));
  }

  const toggleRow = (row) => {
    if (isRowLoading(row)) return;

    if (row.getIsExpanded()) {
      row.toggleExpanded();
    } else {
      row.toggleExpanded();
      fetchRow(row);
    }
  }

  const columns = [
    columnHelper.accessor("file_name", {
      header: "Submission",
      cell: ({ row, getValue }) => {
        return (
          <>
            <div
              className="btn btn--sqr"
              style={{ marginRight: 18, verticalAlign: "middle" }}
              onClick={() => toggleRow(row)}
            >
              <ExpandMore fontSize="small" />
            </div>
            <span className="t--500">{getValue()}</span>
          </>
        );
      },
    }),
    columnHelper.accessor((row) => row.status, {
      header: "Status",
      cell: ({ getValue }) => {
        return (
          <div className="badge">
            <div className={`badge__indicator badge__indicator--${getValue().toLowerCase()}`} />
            {getValue()}
          </div>
        );
      },
    }),
    columnHelper.accessor("row_count", {
      header: "Claims",
      cell: (info) => <div className="t--500">{info.renderValue()}</div>,
    }),
    columnHelper.accessor("conforming_percent", {
      header: "Conforming",
      cell: ({ getValue }) => {
        return (
          <div className="progress_bar__wrapper">
            <div className="progress_bar__container progress_bar__container--t-fixed">
              <div className="progress_bar__fill" style={{ width: `${getValue()}%` }} />
            </div>
            <div className="progress_bar__value">{`${getValue()}%`}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor("date", {
      header: "Date",
    }),
    columnHelper.accessor("links", {
      header: "actions",
      cell: ({ getValue }) => {
        return (
          <>
            {/* <a href={getValue().report} disposition="attachment">
              <div className="btn btn--light" style={{ marginRight: 10 }}>
                View Report
              </div>
            </a> */}
            <div className="btn btn--sqr">
              <a href={getValue().download} download>
                <GetApp style={{ fontSize: 14 }} />
              </a>
            </div>
          </>
        );
      },
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  const renderSubComponent = (row) => {
    return <SubmissionSummaryChart row={row} />;
  }

  const renderRows = () => {
    return table.getRowModel().rows.map((row) => {
      return (
        <Fragment key={row.id}>
          <tr className="sm">
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className="t--md">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
          {row.getIsExpanded() && (
            <tr>
              { isRowLoading(row) ?
                <LoadingIndicator /> :
                <td colSpan={row.getVisibleCells().length}>{renderSubComponent(row)}</td>
              }
            </tr>
          )}
        </Fragment>
      );
    });
  };

  return (
    <div>
      <table className="primary">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="sm">
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
      <Notice details={notice} />
    </div>
  );
}
